import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Modal } from 'antd';
import CommentForm from '../../summary_report/components/CommentForm';
import ModalTitle from '../../common/components/ModalTitle';
import ApplicationReportsStore from '../stores/ApplicationReportsStore';
import { ReportStatus } from '../../projects/types/ApplicationReport';
import { Utils } from '../../common/misc/Utils';

type Props = {
    store: ApplicationReportsStore;
    status: ReportStatus;
    isModalVisible: boolean;
    closeDialog: () => void
};

const NotAvailableValueDialog: React.FC<Props> = ({store, isModalVisible, closeDialog, status}) => {
    const [form] = Form.useForm();    

    const handleSaveClick = () => {
        form.validateFields().then((values) => {
            const comment = Utils.removeNonBreakingSpaces(values.comment);

            if (!store.currentLotData.topic) {
                store.setSectionStatus(comment, status);
            } else if (!store.currentLotData.lot) {
                store.setTopicStatus(comment, status);
            } else {
                store.setLotNotAvailable(comment, status);
            }
                      
            handleDialogClose();
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleDialogClose = () => {
        form.resetFields(['comment']);
        closeDialog();
    };

    const title = status === ReportStatus.NotAvailable ? 'Set as N/A': 'Need for the project';

    return (
        <Modal
            title={<ModalTitle title={title} onCloseCallback={handleDialogClose}/>}
            visible={isModalVisible}
            className="roche-client-dialog large-input comment-in-modal"
            closable={false}
            zIndex={10000}
            footer={[
                <Button 
                    data-id="assign-cancel-button"
                    key="assign-cancel-button" 
                    className="light"
                    onClick={handleDialogClose}
                    size='large'
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="assign-save-button"
                    key="assign-save-button" 
                    type="primary"
                    size='large'
                    onClick={handleSaveClick}
                >
                    {title}
                </Button>
            ]}
        >
            <Form form={form} className="alpha-form" layout='vertical'>
                <CommentForm form={form} label='Comment' isRequired/>
            </Form>
        </Modal>

    );
    
};

export default observer(NotAvailableValueDialog);

