export type MethodSheetSummaryData = {
    status: ProjectStatus;
    version: string;
    completed: number;
    dateModified: string;
    modifiedByUserName: string;
    modifiedBy: string
};

export enum ProjectStatus {
    NotStarted = 'Not Started',
    InProgress = 'In Progress',
    InReview = 'In Review',
    Completed = 'Completed',
    IsArchived = 'Archived'
}