import { Card, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SpecificationTypes } from '../../projects/types/ApplicationReport';
import ApplicationReportsStore from '../stores/ApplicationReportsStore';
import ApplicationReport from './ApplicationReport';
const { TabPane } = Tabs;

type Props = {
    store: ApplicationReportsStore
};

const ApplicationReportsView: React.FC<Props> = ({store}) => {
    return (
        <>
            <Card data-id='ApplicationReportsContainer' data-testid='application-report-table' className='verification-plan-container' title='Application Reports' bordered={false}>
                <Tabs activeKey={store.currentSpecTypeId} defaultActiveKey={SpecificationTypes.Reagent} onChange={(id) => store.setCurrentSpecType(id)}>
                    <TabPane 
                        tab={
                            <span data-id='NavWraper-reagent'>
                                {`Reagent (${store.getStatisticsBySpec(SpecificationTypes.Reagent)})`}
                                <div className={`unread-comment-indicator ${store.reagentHasUnreadComment ? 'visible': ''}`}/>
                            </span>} 
                        key={SpecificationTypes.Reagent}
                    >
                        <ApplicationReport store={store}/>
                    </TabPane>
                    <TabPane 
                        tab={
                            <span data-id='NavWraper-callibrators'>
                                {`Calibrators (${store.getStatisticsBySpec(SpecificationTypes.Calibrators)})`}
                                <div className={`unread-comment-indicator ${store.calibratorsHasUnreadComment ? 'visible': ''}`}/>
                            </span>}
                        key={SpecificationTypes.Calibrators}
                    >
                        <ApplicationReport store={store} />
                    </TabPane>
                    <TabPane 
                        tab={
                            <span data-id='NavWraper-preciControl'>
                                {`PreciControl (${store.getStatisticsBySpec(SpecificationTypes.PreciControl)})`}
                                <div className={`unread-comment-indicator ${store.preciControlHasUnreadComment ? 'visible': ''}`}/>
                            </span>} 
                        key={SpecificationTypes.PreciControl}
                    >
                        <ApplicationReport store={store} />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    );
};

export default observer(ApplicationReportsView);