import { Divider, Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
// import { AppPermissions } from '../../authorization/Permissions';
// import { hasPermission } from '../../authorization/components/HasPermission';
import DocumentTabs from '../../projects/components/DocumentTabs';
import projectsRoute from '../../projects/routes';
import { useStore } from '../stores/context';
import UserProfile from './UserProfile';
// import authContext from '../../authorization/AuthContext';

const { Header, Content } = Layout;

const MainLayout: React.FC = () => {
    const { tabsStore, userProfileStore } = useStore();
    React.useEffect(() => {
        userProfileStore.getUserInfo();
    });
    const navigate = useNavigate();
    // const authCtx = React.useContext(authContext);

    const menuItem = (iconClass: string, title: string, key: string, dataId: string, clickHandler: () => void) => {
        return (
            <Menu.Item data-id={dataId} key={key} onClick={clickHandler}>
                <i className={`roche-icon ${iconClass} md middle`}/>
                <div style={{paddingTop: 1, display: 'inline-block'}}>{title}</div>
            </Menu.Item>
        );
    };    

    const determineMenuSelection = () => {
        let selectedKeys: string[] = [];
        if (window.location.pathname.includes('audit')) {
            selectedKeys = ['2'];
        } else if (window.location.pathname.endsWith('projects')) {
            selectedKeys = ['1'];
        }

        return selectedKeys;
    };

    const handleProjectItemClick = ()=> {
        tabsStore.setSelectedTab('Projects');
        navigate('/projects');
    };
    
    const handleAuditItemClick = ()=> {
        tabsStore.setSelectedTab('Audit');
        navigate('/audit');
    };

    const generateMenuItemsForProject = () => {
        let menuItems: JSX.Element[] = [];
        menuItems.push(menuItem('folder', 'Projects', '1', 'main-menu-projects', handleProjectItemClick ));
        // Disabled role check for audit menu item
        // if (hasPermission(authCtx.permissions, AppPermissions.CanReadAudit)) {
        menuItems.push(menuItem('folder', 'Audit', '2', 'main-menu-audit', handleAuditItemClick ));
        // }

        return menuItems;
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.currentTarget.classList.add('dragging');
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        if (e.currentTarget === e.target) {
            e.stopPropagation();
            e.currentTarget.classList.remove('dragging');
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.currentTarget.classList.remove('dragging');
    };

    return(
        <Layout className='main-layout'>
            <Header className='app-page-header'>
                <div className='header-content'>
                    <div className="logo-container">
                        <i className="alpha-icon logo lg"/>
                        <Divider type='vertical' className='logo-divider'/>
                        <div className="roche-logo"/>
                    </div> 
                    <div className='initial-tabs'>
                        <div className="main-menu-container">
                            <Menu selectedKeys={determineMenuSelection()} theme="light" mode="horizontal" style={{lineHeight: '50px', height: 50}}>
                                {generateMenuItemsForProject()}
                            </Menu>
                        </div>
                    </div>
                    <Divider type='vertical' className='tab-divider'/>
                    <DocumentTabs tabsStore={tabsStore}/>
                    <div className="controls-container">
                        {/* <i className="roche-icon bell md"/> */}
                        <UserProfile />
                    </div>
                </div>
            </Header>
            <Content 
                className="main-layout-content"
                onDragEnter={handleDragEnter}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <Routes>
                    {projectsRoute()}
                    <Route path='/' element={
                        <Navigate replace to={'/projects'} />
                    }/>
                </Routes>
            </Content>
            <div className="app-version-label">{`© Alpha. Version: R${process.env.REACT_APP_VERSION}`}</div>
        </Layout>
    );
};

export default observer(MainLayout);