import { Card, Row, Col, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Utils } from '../../common/misc/Utils';
import { ProjectStatus } from '../models/types';
import MethodSheetReportStore from '../stores/MethodSheerReportStore';

type Props = {
    handleReportOpenClick: () => void;
    store: MethodSheetReportStore
};

const MethodSheetReportView: React.FC<Props> = ({store, handleReportOpenClick}) => {
    // const ActionDotsRenderer = (
    //     <Button style={{ border: 'none', marginRight: 10 }} size="small" type="ghost">
    //         <i style={{ verticalAlign: 'middle', height: 4 }} className="roche-icon md action-more-dots" />
    //     </Button>
    // );

    // const actionMenu = (
    //     <Menu>
    //         <Menu.Item key='open'>Open</Menu.Item>
    //         <Menu.Item key='review'>Send to review</Menu.Item>
    //         <Menu.Item key='download'>Download</Menu.Item>
    //     </Menu>
    // );


    return (
        <Card title='Method Sheet Application Report Summary' className='method-sheet-report' bordered={false}>
            <Row justify='space-between'>
                <Col>
                    <div className='label'>Status</div>
                    <div>{ProjectStatus[store.methodSheetReportData.status]}</div>
                </Col>
                {/* <Col>
                    <div className='label'>Version</div>
                    <div>{store.methodSheetReportData.version}</div>
                </Col> */}
                <Col>
                    <div className='label'>Completed</div>
                    <div>{`${store.methodSheetReportData.completed}%`}</div>
                </Col>
                <Col>
                    <div className='label'>Date modified</div>
                    <div>{Utils.formatDateStringShort(store.methodSheetReportData.dateModified, true, true)}</div>
                </Col>
                <Col>
                    <div className='label'>Modified by</div>
                    <div>{store.methodSheetReportData.modifiedByUserName}</div>
                </Col>
                <Col>
                    {/* <Dropdown
                        trigger={['click']}
                        overlay={actionMenu}
                    >
                        {ActionDotsRenderer}
                    </Dropdown> */}
                    <Button  
                        type="primary" 
                        onClick={handleReportOpenClick}
                    > Open report
                    </Button>
                </Col>
            </Row>
        </Card>);
};
export default observer(MethodSheetReportView);


