import { Button, Col, Row, Switch, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { generateStatusIcon } from '../../common/components/ProjectStatusRenderer';
import SummaryReportStore from '../stores/SummaryReportStore';

type Props = {
    store: SummaryReportStore
};

const SummaryReportHeaderControls: React.FC<Props> = ({store}) => {
    return (
        <Row style={{alignItems: 'center', marginBottom: 25}}>
            <Col style={{display: 'flex', alignItems: 'center'}}>
                <h1 style={{fontSize: 34, marginBottom: 3}}>Method Sheet Application Report Summary</h1>
                <div className='header-details'>
                    <div className='status'>{generateStatusIcon(store.methodSheetReportData?.status)}</div>
                </div>
            </Col>
            <Col style={{marginLeft: 'auto'}}>
                <div className='table-container-header page-header'>
                    <Switch onChange={store.setConciseView} checked={store.isConciseViewEnabled} style={{marginRight: 10}}/>
                    <span style={{color: '#9BA0AA'}}>Concise view</span>
                    <Tooltip title="Generate Excel" placement="bottom">
                        <Button 
                            data-id="button-columns" 
                            className="dropdown-btn columns-btn" 
                            onClick={store.handleDownloadReport} 
                            style={{border: 'none', marginLeft: 16, fontSize: 14}} 
                            type="ghost"
                        >
                            <i className="roche-icon md download"/>
                        </Button>
                    </Tooltip>
                    <Button disabled size="large" className='large' type='primary'>
                        Send to review
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default observer(SummaryReportHeaderControls);