import { Col, Divider, Dropdown, Menu, Row, Tooltip, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { DropdownWhenScroll } from '../../common/components/DropdownWhenScroll';
import { ReportStatus } from '../../projects/types/ApplicationReport';
import { LotType } from '../../summary_report/types/TopicLotResult';
import ApplicationReportsStore from '../stores/ApplicationReportsStore';
import NotAvailableValueDialog from './NotAvailableValueDialog';
import { PackageState } from '../../common/types/PackageState';
import NewContractDialog from './NewContractDialog';
import CommentsDialog from '../../summary_report/components/CommentsDialog';
import { useProjectsStore } from '../../projects/stores/context';
import { CellType } from '../../projects/types/CommentData';
import { useStore } from '../../common/stores/context';
import ConfirmationDialog from '../../common/components/ConfirmationDialog';
import _ from 'lodash';
import ResultHelpers from '../../common/misc/ResultHelpers';
import LotsBulkUpdateMenu from './LotsBulkUpdateMenu';
import ARReportLayoutMenu from './ARReportLayoutMenu/ARReportLayoutMenu';
import HiddenSectionTopicsMenu from './HiddenSectionTopicsMenu/HiddenSectionTopicsMenu';
import DocStatusHelpers from '../../common/misc/DocStatusHelpers';

type Props = {
    store: ApplicationReportsStore
};

type ResultColumnProps = {
    store: ApplicationReportsStore;
    setApplyShadow: (applyShadow: boolean) => void;
    setIsNotAvailableDialogVisible: (isNotAvailableDialogVisible: boolean) => void;
    setIsAvailableDialogVisible: (isAvailableDialogVisible: boolean) => void
};

type LotData = {
    docId: string;
    docName: string;
    status: PackageState;
    unavailable?: boolean;
    commentsCount: number;
    errorMessage: string | null;
    notReadCommentsCount: number;
    overridingPackageId?: string;
    overridingPackageName?: string
};

type Lot = {
    lotType: LotType;
    lotData: LotData
};

export type ApplicationReportsTableModel = {
    section: string;
    topic: string;
    lots: Lot[]
};

const ResultColumn: React.FC<ResultColumnProps> = observer(({ store, setApplyShadow, setIsAvailableDialogVisible, setIsNotAvailableDialogVisible }) => {
    const { userProfileStore } = useStore();
    const { commentsStore } = useProjectsStore();

    const [isSelectDocDialogVisible, setIsSelectDocDialogVisible] = React.useState<boolean>(false);
    const [isCommentsDialogVisible, setIsCommentsDialogVisible] = React.useState(false);
    const [isConfirmationDialogVisibile, setIsConfirmationDialogVisibile] = React.useState(false);
    
    const resultColumnRef = React.useRef<HTMLDivElement>(null);
    const scrollElementRef = React.useRef<HTMLDivElement>(null);

    const handleResultScroll: React.EventHandler<React.UIEvent<React.ReactNode>> = React.useCallback((
        event: React.UIEvent<React.ReactNode>
    ) => {
        const targetDiv: HTMLDivElement = event.target as HTMLDivElement;

        if (targetDiv === resultColumnRef.current && scrollElementRef.current) {
            scrollElementRef.current.scrollLeft = targetDiv.scrollLeft;
        } else if (targetDiv === scrollElementRef.current && resultColumnRef.current) {
            resultColumnRef.current.scrollLeft = targetDiv.scrollLeft;
        }

        setApplyShadow(targetDiv.scrollLeft > 0);
    }, [setApplyShadow]);

    React.useEffect(() => {
        if (!isSelectDocDialogVisible) {
            store.resetNewContractDialogState();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [isSelectDocDialogVisible]);

    const handleCommentDialogOpen = ( section: string, topic: string, lotType: LotType, notReadCommentsCount: number) => {
        commentsStore.setCommentDetails(section, topic, store.currentSpecTypeId, CellType.Lot, notReadCommentsCount, lotType);
        setIsCommentsDialogVisible(true);
    };

    const isArchived = store.currentProject?.isArchived;

    const documentRemoveConfirmation = {
        confirmButtonText: 'Remove document',
        cancelHandler: () => setIsConfirmationDialogVisibile(false)
    };

    const handleDocumentRemove = (docId: string) => {
        store.setDocToRemove(docId);
        setIsConfirmationDialogVisibile(true);
    };

    const notAvailableDocActionsMenu = (lotData: LotData, section: string, topic: string, lot: LotType) => {
        return(
            <Menu className='table-actions-menu' data-id-type="popup-menu-list-root">
                <Menu.Item key='project' data-id-type="popup-menu-list-item">
                    <div data-testid='open-spec-doc' className='action-container' onClick={() => handleSetAvailable(section, topic, lot)}> 
                        <i className="roche-icon md doc-add" />
                        <div>Need for the project</div>
                    </div>
                </Menu.Item>
                <Menu.Item data-id-type="popup-menu-list-item" key='comments' onClick={() => handleCommentDialogOpen(section, topic, lot, lotData.notReadCommentsCount)}> 
                    <div data-testid='open-spec-doc' className='action-container'> 
                        <i className="roche-icon md comments" />
                        <div>View comments</div>
                    </div>
                </Menu.Item>
            </Menu>
        );
    };

    const approvedDocActionsMenu = (lotData: LotData, section: string, topic: string, lot: LotType) => {
        const lotDocName = lotData.overridingPackageName ? lotData.overridingPackageName : lotData.docName;

        return(
            <Menu className='table-actions-menu' data-id-type="popup-menu-list-root">
                <Menu.Item key='open' className='bordered' data-id-type="popup-menu-list-item">
                    <a 
                        href={`${process.env.PUBLIC_URL}/projects/${store.projectId}/documents/${encodeURIComponent(lotData.docId)}/${encodeURIComponent(lotDocName)}`} 
                        target="_blank" rel="noreferrer"
                    >
                        <div data-testid='open-doc' className='action-container'> 
                            <i className="roche-icon md doc" />
                            <div>Open</div>
                        </div>
                    </a>
                </Menu.Item>
                <Menu.Item key='download' data-id-type="popup-menu-list-item">
                    <div data-testid='download-doc' className='action-container' onClick={() => store.downloadDocument(lotData.docId)}> 
                        <i className="roche-icon md download" />
                        <div>Download</div>
                    </div>
                </Menu.Item>
                <Menu.Item 
                    key='comments' 
                    className='bordered' 
                    onClick={() => handleCommentDialogOpen(section, topic, lot, lotData.notReadCommentsCount)}
                    data-id-type="popup-menu-list-item"
                > 
                    <div data-testid='view-comments-doc' className='action-container'> 
                        <i className="roche-icon md comments" />
                        <div>View comments</div>
                    </div>
                </Menu.Item>
                <Menu.Item  key='remove' data-id-type="popup-menu-list-item" onClick={() => handleDocumentRemove(lotData.docId)}>
                    <div data-testid='remove-doc' className='action-container'> 
                        <i className="roche-icon md remove" />
                        <div style={{color: '#F5222D'}}>Remove</div>
                    </div>
                </Menu.Item>
            </Menu>);
    };

    const getDocIcon = (status: PackageState) => {
        switch (status) {
        case PackageState.UploadedWithoutAnalysis:
        case PackageState.Completed:
            return 'roche-icon md doc-small-completed';
        case PackageState.Broken:
            return 'roche-icon md warning-triangle';
        case PackageState.Processing:
            return 'roche-icon md table-search';
        case PackageState.Importing:
        case PackageState.Uploading:
            return 'roche-icon md importing-loading';
        default:
            return '';
        }
    };

    const getCommentsContainer = (lotData: LotData, section: string, topic: string, lotType: LotType) => {
        return !!lotData.commentsCount && 
        <div 
            className={lotData.notReadCommentsCount > 0 ? 'comments-count red' : 'comments-count'} 
            onClick={() => handleCommentDialogOpen(section, topic, lotType, lotData.notReadCommentsCount)}
            style={{pointerEvents:  isArchived ? 'none' : 'auto'}}
        >
            <i className={lotData.notReadCommentsCount > 0 ? 'roche-icon md not-read-comment' : 'roche-icon md comment'}/>
            <div>{lotData.commentsCount > 9 ? '+9' : lotData.commentsCount}</div>
        </div>;
    };

    const processedCellRenderer = (lotData: LotData, section: string, topic: string, lotType: LotType) => {
        return (
            <>
                <Row>
                    <Col>
                        <i style={{height: 12, verticalAlign: 'middle'}} className={getDocIcon(lotData.status)} />
                    </Col>
                    <Col xxl={20} xl={18} lg={16} md={14} className='doc-column'>
                        <Tooltip title={lotData.overridingPackageName ?? lotData.docName}>
                            <div className='doc-name-wrapper'>
                                <span style={{cursor: 'pointer'}} className='doc-name'>{lotData.overridingPackageName ?? lotData.docName}</span>
                            </div>
                        </Tooltip>
                    </Col>
                    <Col className='actions'>
                        <Dropdown
                            trigger={['click']}
                            overlay={approvedDocActionsMenu(lotData, section, topic, lotType)}
                            disabled={isArchived}
                        >
                            <i style={{height: 6, cursor: getCursorStyle()}} className="roche-icon md action-more-dots" />
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col className='status' offset={2} style={{marginLeft: 20}}>
                        <Tooltip title={lotData.errorMessage}>
                            <span style={{cursor: lotData.status === PackageState.Broken ? 'pointer' : 'default'}}>{DocStatusHelpers.getDocStatusName(lotData.status)}</span>
                        </Tooltip>
                    </Col>
                </Row>
                {getCommentsContainer(lotData, section, topic, lotType)}
            </>
        );
    };

    const handleBeforeUpload = (file: RcFile, lot: LotType, section: string, topic: string, skipAnalysis: boolean = false) => {
        store.setFileToUpload(file);
        store.uploadDocument(ReportStatus.Approved, lot, section, topic,  store.currentSpecTypeId, skipAnalysis);
        return false;
    };

    const handleDocumentSelect = (lot: LotType, section: string, topic: string) => {
        store.setCurrentLotData(section, topic, lot);
        setIsSelectDocDialogVisible(true);
    };

    
    const uploadActionsMenu = (lot: LotType, section: string, topic: string) => {
        return(
            <Menu className='table-actions-menu' style={{minWidth: 180}} data-id-type="popup-menu-list-root">
                <Menu.Item key='upload-doc' data-id-type="popup-menu-list-item">
                    <Observer>
                        {() => (
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file: RcFile) => handleBeforeUpload(file, lot, section, topic)}
                            >
                                <div data-testid='open-spec-doc' className='action-container'> 
                                    <i className="roche-icon md checked-doc" />
                                    <div>Upload document</div>
                                </div>
                            </Upload>
                        )}
                    </Observer>
                </Menu.Item>
                <Menu.Item key='select-doc' data-id-type="popup-menu-list-item">
                    <div data-testid='open-spec-doc' className='action-container' onClick={() => handleDocumentSelect(lot, section, topic)}> 
                        <i className="roche-icon md raw-doc" />
                        <div>Select document</div>
                    </div>
                </Menu.Item>
            </Menu>);
    };

    const handleSetUnavailable = (section: string, topic: string, lot: LotType) => {
        setIsNotAvailableDialogVisible(true);
        store.setCurrentLotData(section, topic, lot);
    };

    const handleSetAvailable = (section: string, topic: string, lot: LotType) => {
        setIsAvailableDialogVisible(true);
        store.setCurrentLotData(section, topic, lot);
    };

    const getCursorStyle = () => {
        return isArchived ? 'not-allowed' : 'pointer';
    };

    const handleUploadHover = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.currentTarget.classList.add('dragging');
    };

    const handleUploadLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.currentTarget.classList.remove('dragging');
    };

    const matrixCellRenderer = (tableData: ApplicationReportsTableModel, lotType: LotType, lotData: LotData) => {    
        if (lotData.unavailable) {
            return (
                <div className='matrix-cell unavailable' data-id-cells={lotType}>
                    <div className='actions'>
                        <Dropdown
                            trigger={['click']}
                            overlay={notAvailableDocActionsMenu(lotData, tableData.section, tableData.topic, lotType)}
                            disabled={isArchived}
                        >
                            <i style={{height: 6, cursor: getCursorStyle()}} className="roche-icon md action-more-dots" />
                        </Dropdown>
                    </div>
                    <div className='not-available'>N/A</div>
                    {getCommentsContainer(lotData, tableData.section, tableData.topic, lotType)}
                </div>
            );
        }
        if (!lotData.docName) {
            return(
                <div className='matrix-cell to-be-added' data-id-cells={lotType}>
                    <div className="controls-container" style={{cursor: getCursorStyle()}}>
                        <DropdownWhenScroll
                            trigger={['click']}
                            overlay={uploadActionsMenu(lotType, tableData.section, tableData.topic)}
                            disabled={isArchived}
                        >
                            <i data-id-cells='PlusButton' className="roche-icon md plus"/>
                        </DropdownWhenScroll>
                        <Tooltip title='set as N/A'>
                            <i data-id-cells='SetButton' className="roche-icon md not-available-doc" style={{ pointerEvents: isArchived ? 'none' : 'auto'}}
                                onClick={() => handleSetUnavailable(tableData.section, tableData.topic, lotType)}/>
                        </Tooltip>
                    </div>
                    <div 
                        className="upload-container"
                        onDragOver={handleUploadHover}
                        onDragLeave={handleUploadLeave}
                    >
                       
                        <Upload.Dragger
                            className="document-uploader"
                            showUploadList={false}
                            beforeUpload={(file: RcFile) => handleBeforeUpload(file, lotType, tableData.section, tableData.topic)}
                        >
                            <span />
                        </Upload.Dragger>
                    </div>
                    {getCommentsContainer(lotData, tableData.section, tableData.topic, lotType)}
                </div>
            );
        } 

        return(
            <div className={`${lotData.status} matrix-cell processed`} data-id-cells={lotType} data-id-status={lotData.status} data-id-name={tableData.topic}>
                {processedCellRenderer(lotData, tableData.section, tableData.topic, lotType)}
            </div>
        );
    };  

    const handleCommentDialogClose = () => {
        setIsCommentsDialogVisible(false);
        store.markReadComments(userProfileStore.userInfo.userId);
    };

    const getDeleteDocConfirmationMessage = () => {
        const doc = store.getDocById(store.docToRemove);
        return doc ? 
            <> Are you sure you want to remove {<span>{`${doc.documentName} `}</span>} 
             from Application reports: {<span>{doc.topic}</span>} - {<span>{doc.lotType}</span>}.</> : <></>;
    };

    const moreThanThreeResults = React.useMemo(
        () => !!(store.currentProject && store.currentProject.resultCount > 3),
        [store.currentProject]
    );

    const columnSpan = React.useMemo(() => {
        if (!store.currentProject || moreThanThreeResults) {
            return;
        }

        switch (store.currentProject.resultCount) {
        case 1:
            return 24;
        case 2:
            return 12;
        case 3:
            return 8;
        default:
            return;
        }
    }, [store.currentProject, moreThanThreeResults]);

    const scrollLeft = () => {
        if (scrollElementRef.current) {
            scrollElementRef.current.scrollTo(scrollElementRef.current.scrollLeft - 298, 0);
        }
    };

    const scrollRight = () => {
        if (scrollElementRef.current) {
            scrollElementRef.current.scrollTo(scrollElementRef.current.scrollLeft + 298, 0);
        }
    };

    const onKeyPress = (event: KeyboardEvent) => {
        switch (event.code) {
        case 'ArrowLeft':
            scrollLeft();
            break;
        case 'ArrowRight':
            scrollRight();
            break;
        default:
            break;
        }
    };


    const onMouseEnter = () => {
        window.addEventListener('keydown', onKeyPress);
    };

    const onMouseLeave = () => {
        window.removeEventListener('keydown', onKeyPress);
    };

    return (
        <div style={{ flex: 1 }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {isSelectDocDialogVisible && <NewContractDialog
                addExistingDocument={store.addExistingDocument}
                alphaPackagesStore={store.alphaPackagesStore} 
                visible={isSelectDocDialogVisible} 
                closeDialog={() => setIsSelectDocDialogVisible(false)}
            />}
            <CommentsDialog 
                commentsStore={commentsStore}
                isModalVisible={isCommentsDialogVisible} 
                closeDialog={handleCommentDialogClose}
                handleCommentSave={store.createComment}
            />
            <ConfirmationDialog 
                text={getDeleteDocConfirmationMessage()}
                confirmation={documentRemoveConfirmation} 
                visible={isConfirmationDialogVisibile} 
                confirmHandler={() => { 
                    store.deleteDocument(); 
                    setIsConfirmationDialogVisibile(false);
                }} 
            />
            
            <div className="results-sticky-container">
                <div ref={resultColumnRef} onScroll={handleResultScroll} className="results-scrollable">
                    {store.currentProjectResultRange.map(resultNumber => (
                        <div key={resultNumber} className="result-label-container">
                            <span className="result-label">Result {resultNumber}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div ref={scrollElementRef} onScroll={handleResultScroll} className="result-column">
                {Object.keys(store.groupedTableDataSource)?.map((key) => 
                    store.currentSpecTypeHiddenParts?.hiddenReportParts[key]?.length === store.groupedTableDataSource[key]?.length 
                        ? null 
                        : (
                            <div className="result-section-container" key={key}>
                                {store.groupedTableDataSource[key]?.map((d, i) =>
                                    store.currentSpecTypeHiddenParts?.hiddenReportParts[d.section]?.includes(d.topic) 
                                        ? null 
                                        : (
                                            <Row
                                                key={i}
                                                className="section-row"
                                                justify="space-between"
                                                style={{ flexFlow: moreThanThreeResults ? 'unset' : undefined }}
                                                data-id={d.topic}
                                            >
                                                {store.currentProjectResultRange.map(resultNumber => {
                                                    const lotType: LotType = ResultHelpers.createLotType(resultNumber);
                                                    const lot = d.lots.find(l => l.lotType === lotType);

                                                    if (!lot) {
                                                        return null;
                                                    }

                                                    const resultLabel =`Result ${resultNumber}`;                        
                                                    return (
                                                        <Tooltip key={resultNumber} title={moreThanThreeResults ? resultLabel : undefined} placement="right">
                                                            <Col span={columnSpan} className={moreThanThreeResults ? 'fixed-size-section-column' : undefined}>
                                                                {matrixCellRenderer(d, lotType, lot.lotData)}
                                                            </Col>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Row>
                                        ))}
                            </div>
                        ))}
            </div>
        </div>
    );
});

const ApplicationReport: React.FC<Props> = ({store}) => {
    const handleScroll = () => {
        const scrollable = document.getElementsByClassName('main-layout-content')[0];
        if (scrollable) {
            const position = scrollable.scrollTop;
            store.setScrollPosition(position);
        }
    };

    React.useEffect(() => {
        const throttled = _.throttle(handleScroll, 1000);
        const scrollable = document.getElementsByClassName('main-layout-content')[0];
        if (scrollable?.scrollTo) {
            scrollable.scrollTo(0, store.scrollPosition);
        }
        scrollable?.addEventListener('scroll', throttled);
        return () => {
            scrollable?.removeEventListener('scroll', throttled);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject?.id]);
    
    const [applyShadow, setApplyShadow] = React.useState(false);
    const [isNotAvailableDialogVisible, setIsNotAvailableDialogVisible] = React.useState<boolean>(false);
    const [isAvailableDialogVisible, setIsAvailableDialogVisible] = React.useState<boolean>(false);

    const setSectionAsNA = (section: string) => {
        setIsNotAvailableDialogVisible(true);
        setIsAvailableDialogVisible(false);
        store.setCurrentLotData(section);
    };

    const setSectionAsAvailable = (section: string) => {
        setIsAvailableDialogVisible(true);
        setIsNotAvailableDialogVisible(false);
        store.setCurrentLotData(section);
    };

    const setTopicAsNA = (section: string, topic: string) => {
        setIsNotAvailableDialogVisible(true);
        setIsAvailableDialogVisible(false);
        store.setCurrentLotData(section, topic);
    };

    const setTopicAsAvailable = (section: string, topic: string) => {
        setIsAvailableDialogVisible(true);
        setIsNotAvailableDialogVisible(false);
        store.setCurrentLotData(section, topic);
    };
   
    const sectionMenu = (section: string) => {
        const sectionLots = _.flatten(store.groupedTableDataSource[section]?.map((d) => d.lots));
        const sectionHasNaLots = sectionLots?.some((lot) => lot.lotData.unavailable === true);
        const sectionHasNeedForProjectLots = sectionLots?.some((lot) => !lot.lotData.docName && lot.lotData.unavailable !== true);

        return (
            <LotsBulkUpdateMenu
                setAsNaDisabled={!sectionHasNeedForProjectLots}
                setNeedForProjectDisabled={!sectionHasNaLots}
                setAsNaHandler={() => setSectionAsNA(section)}
                setNeedForProjectHandler={() => setSectionAsAvailable(section)}
                scope={'section'}
            />
        );
    };

    const topicMenu = (section: string, topic: string) => {
        const topicLots = _.flatten(store.groupedTableDataSource[section]?.filter((d) => d.topic === topic)?.map((d) => d.lots));
        const topicHasNaLots = topicLots?.some((lot) => lot.lotData.unavailable === true);
        const topicHasNeedForProjectLots = topicLots?.some((lot) => !lot.lotData.docName && lot.lotData.unavailable !== true);

        return (
            <LotsBulkUpdateMenu
                setAsNaDisabled={!topicHasNeedForProjectLots}
                setNeedForProjectDisabled={!topicHasNaLots}
                setAsNaHandler={() => setTopicAsNA(section, topic)}
                setNeedForProjectHandler={() => setTopicAsAvailable(section, topic)}
                scope={'topic'}
            />
        );
    };   
    
    const handleNotAvailableDialogClose = () => {
        setIsNotAvailableDialogVisible(false);
        setIsAvailableDialogVisible(false);
    };

    return (
        <div data-testid='application-reports-container'>
            
            <NotAvailableValueDialog 
                store={store} 
                status={isAvailableDialogVisible ? ReportStatus.Draft : ReportStatus.NotAvailable}
                isModalVisible={isNotAvailableDialogVisible || isAvailableDialogVisible} 
                closeDialog={handleNotAvailableDialogClose}
            />
            <div data-id='Statistics' className='statuses'>
                <Row>
                    <Col span={2}>
                        <div className='label'>Total</div>
                        <h3 className='value'>{store.statisticsData.total}</h3>
                    </Col>
                    <Col span={2}>
                        <div className='label'>Uploaded</div>
                        <h3 className='value'>{store.statisticsData.uploaded}</h3>
                    </Col>
                    <Col span={2}>
                        <Divider type='vertical' style={{height: '75%'}}/>
                    </Col>
                    <Col span={2}>
                        <div className='label'>To be added</div>
                        <h3 className='value'>{store.statisticsData.toBeAdded}</h3>
                    </Col>
                    <Col span={16}>
                        <ARReportLayoutMenu store={store}/>
                    </Col>
                </Row>
            </div>

            <Divider style={{ margin: 0 }} />

            <div data-id='MatrixTable' className='doc-matrix-table'>
                <div
                    className="topic-column"
                    style={{
                        boxShadow: applyShadow ? '5px 0 5px -5px #0000001A' : undefined
                    }}
                >
                    {Object.keys(store.groupedTableDataSource)?.map(key =>
                        store.currentSpecTypeHiddenParts?.hiddenReportParts[key]?.length === store.groupedTableDataSource[key]?.length 
                            ? null 
                            : (
                                <div className="topic-section-container" key={key}>
                                    <div className={`section ${store.currentSpecTypeHiddenParts?.hiddenReportParts?.[key]?.length ? 'has-menu' : '' }`}>
                                        <div className="section-title-content">
                                            <div className='section-label'>{key}</div> 
                                            {sectionMenu(key)}
                                        </div>
                                        
                                        {!store.currentSpecTypeHiddenParts?.hiddenReportParts?.[key]?.length 
                                            ? null 
                                            : <HiddenSectionTopicsMenu store={store} section={key} />}
                                    </div>
                                    {store.groupedTableDataSource[key]?.map((d, i) =>
                                        store.currentSpecTypeHiddenParts?.hiddenReportParts[key]?.includes(d.topic) 
                                            ? null 
                                            : (
                                                <Row key={i} className="section-row" justify="space-between">
                                                    <Col className="label">
                                                        <div className='content-flex'>
                                                            <div className='label-text' >{d.topic}</div>
                                                            <div className='label-button'>{topicMenu(key, d.topic)}</div>
                                                        </div> 
                                                    </Col>
                                                </Row>
                                            ))}
                                </div>
                            ))}
                </div>
                
                <ResultColumn 
                    store={store} 
                    setApplyShadow={setApplyShadow} 
                    setIsAvailableDialogVisible={setIsAvailableDialogVisible} 
                    setIsNotAvailableDialogVisible={setIsNotAvailableDialogVisible} 
                />
            </div>
        </div>
    );
};

export default observer(ApplicationReport);