import { Avatar, Card, Divider, Spin } from 'antd';
import {  observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/misc/Utils';
import { ProjectDashboardStore } from '../stores';
import SpecificationDocumentTable from './SpecificationDocumentTable';
import { useNavigate } from 'react-router';
import ApplicationReportsView from '../../application_report/components/ApplicationReportsView';
import ApplicationReportsStore from '../../application_report/stores/ApplicationReportsStore';
import { getFormattedHistory } from '../misc/HistoryMapper';
import { useStore } from '../../common/stores/context';
import MethodSheetReportView from '../../method_sheet_report/components/MethodSheetReportView';
import { Project } from '../types';
import { UpCircleOutlined } from '@ant-design/icons';
import { generateStatusIcon } from '../../common/components/ProjectStatusRenderer';

type Props = {
    store: ProjectDashboardStore;
    applicationReportsStore: ApplicationReportsStore
};


const ProjectDashboardView: React.FC<Props> = ({store, applicationReportsStore}) => {
    const getApplicationLabel = (project: Project | undefined) => {
        if (!project) {
            return null;
        }
        const { applicationIsNormal, applicationIsStat } = project;
        return applicationIsNormal && applicationIsStat ? 'Normal, STAT' : applicationIsNormal ? 'Normal' : applicationIsStat ? 'STAT' : null;
    };

    const getProjectDetailsField = (project: Project | undefined) => {
        if (!project) {
            return null;
        }

        const projectDetailsFields = [
            {label: 'Project Identifier', value: project.shortId ?? project.id},
            {label: 'Product Name', value: project.productName},
            {label: 'Project Name', value: project.name},
            {label: 'Development Lead', value: project.developmentLead},
            {label: 'Application', value: getApplicationLabel(project)},
            {label: 'GPID', value: project.gpid},
            {label: 'LPN', value: project.lpn},
            {label: 'DIAdoc Version', value: project.diaDocVersion},
            {label: 'Kit Size', value: project.kitSize},
            {label: 'Material Number Assay', value: project.materialNumberAssay},
            {label: 'MS Version assay', value: project.msVersionAssay},
            {label: 'Material Number CalSet', value: project.materialNumberCalSet},
            {label: 'MS Version CalSet', value: project.msVersionCalSet},
            {label: 'Name of the CalSet', value: project.nameOfTheCalSet},
            {label: 'Inpack', value: Utils.capitalizeFirstLetter(project.inpack)},
            {label: 'Material numbers PreciControl 1', value: project.materialNumbersPreciControl1},
            {label: 'Names PreciControl 1', value: project.namesPreciControl1},
            {label: 'MS Version PreciControl 1', value: project.msVersionPreciControl1},
            {label: 'Material numbers PreciControl 2', value: project.materialNumbersPreciControl2},
            {label: 'Names PreciControl 2', value: project.namesPreciControl2},
            {label: 'MS Version PreciControl 2', value: project.msVersionPreciControl2},
            {label: 'Instruments', value: project.analyzerName},
        ];

        return projectDetailsFields;
    };

    React.useEffect(() => {
        store.getMethodSheetSummary();

        const historyFetchInterval = setInterval(() => {
            store.getHistory(true);
        }, 60000);

        return () => clearInterval(historyFetchInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (store.project) {
            const nonNullFields = getProjectDetailsField(store.project)!.filter(field => field.value);
            setShowViewMoreDetails(nonNullFields.length > 7);
            setDetailsCollapsed(true);
            setLeftPanelCollapsed(sessionStorage.getItem('project-dashboard-collapsed') === 'true');
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.project]);

    const [ detailsCollapsed, setDetailsCollapsed ] = React.useState(true);
    const [ showViewMoreDetails, setShowViewMoreDetails ] = React.useState(false);
    const [ leftPanelCollapsed, setLeftPanelCollapsed ] = React.useState(false);
    const { userProfileStore } = useStore();
    const navigate = useNavigate();
    const loadingLayout = (
        <div data-testid="project-dashboard-layout-loader" style={{textAlign: 'center'}}>
            <Spin />
        </div>
    );

    if (!store.project || store.isLoadingHistory || applicationReportsStore?.isLoading || store.isLoadingMedhotSheetSummaryData) {
        return loadingLayout;
    }

    const handleReportOpenClick = () => {
        navigate('summary-report');
    };

    const getTransformedActivityDate = (date: string, diffMins: number) => {
        if (diffMins < 60) {
            if (diffMins === 0) {
                return 'Just now';
            }
            return `${diffMins} minutes ago`;
        }
        return Utils.formatDateStringShort(date, false, true);
    };
   
    const renderProjectDetails = () => {
        if (!store.project) {
            return null;
        }

        const projectDetailsFields = getProjectDetailsField(store.project);

        if (!projectDetailsFields) {
            return null;
        }

        let nonNullFields = projectDetailsFields.filter(field => field.value);

        if (detailsCollapsed) {
            nonNullFields = nonNullFields.slice(0, 7);
        }

        return (<>
            {nonNullFields.map((field) => (
                <div key={field.label} className='session-info'>
                    <div className='label'>{field.label}</div>
                    <div>{field.value}</div>
                </div>
            ))}
        </>);
    };

    
    const scrollToTop = () => {
        const scrollable = document.getElementsByClassName('main-layout-content')[0];
        scrollable.scrollTo(0, 0);
    };

    const toggleDetails = () => {
        setLeftPanelCollapsed(!leftPanelCollapsed);
        sessionStorage.setItem('project-dashboard-collapsed', leftPanelCollapsed ? 'false' : 'true');
    };

    return (
        <div className="project-dashboard-wrapper" data-testid="project-dashboard-content">
            <UpCircleOutlined className='scroll-to-top' onClick={scrollToTop}/>
            <div className='header'>
                <div className="side-collapser" onClick={toggleDetails}>
                    <i className="roche-icon lg close-panel-icon" />
                </div>
                <div className='project-title'>{store.project.name}</div>
                {generateStatusIcon(store.projectStatus)}
                {/* <div data-id-name="Status" className="not-started-status">Not started</div> */}
                {/* <div className='right-container'>
                    <i className="roche-icon md layout" />
                    <span>Layout</span>
                </div> */}
            </div>
            <div className={`content ${leftPanelCollapsed ? 'collapsed' : ''}`}>
                <div className='left-container'>
                    <div className='roche-card open-sessions-card'>
                        <Card title='Project details' bordered={false} className={detailsCollapsed ? 'collapsed' : undefined}>
                            {renderProjectDetails()}
                            {showViewMoreDetails && (
                                <div data-id='button-view-all' className='view-all' onClick={() => setDetailsCollapsed(!detailsCollapsed)}>
                                    Show {detailsCollapsed ? 'more' : 'less'}
                                </div>
                            )}
                        </Card>
                    </div>
                    <div data-id='activity-container' className='activities-container'>
                        <Card title='Activity' bordered={false}>
                            <>
                                {store.filteredHistory.map((h, i) => {
                                    const formattedHistory = getFormattedHistory(h);

                                    if (formattedHistory === null) {
                                        return null;
                                    }

                                    return(
                                        <div key={h.id}>
                                            <div className='activity'>
                                                <div><Avatar size={24} src={userProfileStore.userProfilePictures[h.userId] }/></div>
                                                <div className='activity-text'>
                                                    {h.userName}{formattedHistory}
                                                </div>
                                            </div>
                                            <div className='activity-date'>{getTransformedActivityDate(h.createDate, h.diffMins)}</div>
                                            {i !== store.filteredHistory.length - 1 && <Divider/>}
                                        </div>
                                    );
                                })}
                                {!store.viewAllHistory && store.history.length > 6
                                && <div data-id='button-view-all' className='view-all' onClick={() => store.setViewAllHistory(true)}>View all</div>}
                            </>
                        </Card>

                    </div>
                </div>
                <div className='right-cotainer'>
                    <MethodSheetReportView store={store.methodSheetReportStore} handleReportOpenClick={handleReportOpenClick}/>
                    <SpecificationDocumentTable store={store}/>
                    <ApplicationReportsView store={applicationReportsStore}/>
                    {/* <OtherReportsView/> */}
                </div>
            </div>
        </div>
    );
};

export default observer(ProjectDashboardView);