import { message } from 'antd';
import { action, makeObservable, observable, runInAction } from 'mobx';
import UserProfileModel from '../../user_profile/models/UserProfileModel';
import { SecurityService } from '../services';
import { UserService } from '../services/UserService';
import { UserInfo } from '../types/UserInfo';

export default class UserProfileStore {
    userInfo: UserInfo;

    editUserProfileVisible: boolean = false;

    imageUrl: string;

    userProfilePictures: { [id: string]: string | undefined} = {};

    constructor(private service: UserService) {
        makeObservable(this, {
            editUserProfileVisible: observable,
            userInfo: observable,
            imageUrl: observable,
            userProfilePictures: observable,
            logout: action.bound,
            setEditUserProfileVisible: action.bound,
            getUserInfo: action.bound,
            updateUserProfile: action,
            getAvatarIcon: action
        });
    }

    logout() {
        SecurityService.inst.logout();
    }

    async getUserInfo() {
        const userInfo =  await this.service.getUserInfo();
        runInAction(() => this.userInfo = userInfo);
        this.userProfilePictures[this.userInfo.userId] = this.imageUrl;
    }

    setEditUserProfileVisible(isVisible: boolean) {
        this.editUserProfileVisible = isVisible;
    }

    async updateUserProfile(model: UserProfileModel) {
        const formData = new FormData();
        for (let key in model) {
            if (model[key]) {
                formData.append(key, model[key]);
            }
        }
        const resp = await this.service.updateUserProfile(formData);
        await resp.asyncMap(async () => {
            message.success('Profile has been successfully updated.');
            await this.getUserInfo();
            runInAction(() => {
                this.userInfo.email = model.email;
                this.userInfo.firstName = model.firstName;
                this.userInfo.lastName = model.lastName;
            });
            this.setEditUserProfileVisible(false);
        }).mapErr(err => {
            const errorMsg = err.data?.title.includes('exists') ? 'User with the same email exists' : err.data?.title;
            message.error(errorMsg);
        });
    }

    async getAvatarIcon(fileId: string) {
        const resp = await this.service.getAvatarIcon(fileId);
        if (!resp) {
            return undefined;
        }
        runInAction(() => this.imageUrl = URL.createObjectURL(resp));
        return resp;
    }

    async setUserProfilePictures(userIds: string[]) {
        const usersInfo = await this.service.getUsersInfo(userIds);
        userIds.forEach(async id => {
            const info = usersInfo.find(u=> u.userId === id);
            if (info && info.avatarIconFileId) {
                const resp = await this.service.getAvatarIcon(info.avatarIconFileId);
                this.userProfilePictures[id] = resp ? URL.createObjectURL(resp!) : undefined;
            } else {
                this.userProfilePictures[id] = undefined;
            }
        });

    }
}