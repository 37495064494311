import { PackageState } from '../types/PackageState';

export default class DocStatusHelpers {
    static getDocStatusName = (status: PackageState) => {
        switch (status) {
        case PackageState.UploadedWithoutAnalysis:
            return 'Completed without extraction';
        default:
            return status.toString();
        }
    };
}