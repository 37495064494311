import { observer } from 'mobx-react-lite';
import './ARReportLayoutMenu.less';
import React from 'react';
import { Button, Popover, Switch } from 'antd';
import ApplicationReportsStore from '../../stores/ApplicationReportsStore';
import { HiddenReportPartModel } from '../../../projects/types';

type Props = {
    store: ApplicationReportsStore
};

const ARReportLayoutMenu: React.FC<Props> = ({store}) => {

    const getButtonText = () => {
        if (!store.currentSpecTypeHiddenParts?.hiddenReportParts || !Object.keys(store.currentSpecTypeHiddenParts?.hiddenReportParts).length) {
            return 'Hide sections & topics';
        }

        const hiddenReportParts = store.currentSpecTypeHiddenParts?.hiddenReportParts;

        let hiddenSectionsCount = 0;
        let hiddenTopicsCount = 0;

        Object.keys(hiddenReportParts ?? {}).forEach(s => {
            if (hiddenReportParts?.[s]?.length === store.groupedTableDataSource[s].length) {
                hiddenSectionsCount++;
            } else if (hiddenReportParts?.[s]?.length) {
                hiddenTopicsCount += hiddenReportParts[s]?.length ?? 0;
            }
        });

        let text = '';

        if (hiddenSectionsCount) {
            text += `${hiddenSectionsCount} hidden section${hiddenSectionsCount > 1 ? 's' : ''}`;
        }

        if (hiddenTopicsCount) {
            text += `${hiddenSectionsCount ? ' & ' : ''}${hiddenTopicsCount}${!hiddenSectionsCount ? ' hidden' : ''} topic${hiddenTopicsCount > 1 ? 's' : ''}`;
        }

        if (!hiddenSectionsCount && !hiddenTopicsCount) {
            text = 'Hide sections & topics';
        }

        return text;
    };

    const isTopicSwitchDisabled = (section: string, topic: string) => {
        const project = store.currentProject;
        if (!project?.hiddenReportParts?.find(p => p.specificationTypeId === store.currentSpecTypeId)?.hiddenReportParts?.[section]?.includes(topic)) {
            return false;
        }

        return true;
    };

    const isSectionSwitchDisabled = (section: string) => {
        const project = store.currentProject;
        if (!project?.hiddenReportParts?.find(p => p.specificationTypeId === store.currentSpecTypeId)?.hiddenReportParts?.[section]?.length) {
            return false;
        }
        const topicsInSection = store.groupedTableDataSource[section].map(t => t.topic);
        return project.hiddenReportParts.find(p => p.specificationTypeId === store.currentSpecTypeId)?.hiddenReportParts?.[section].length === topicsInSection.length;
    };

    const onChangeSectionSwitch = (section: string, checked: boolean) => {
        const project = store.currentProject;
        if (!project) {
            return;
        }

        const topicsInSection = store.groupedTableDataSource[section].map(t => t.topic);
        const existingHiddenReportPart = project.hiddenReportParts?.find(p => p.specificationTypeId === store.currentSpecTypeId);
        let hiddenReportParts = existingHiddenReportPart ?? new HiddenReportPartModel();

        if (!hiddenReportParts.hiddenReportParts) {
            hiddenReportParts.hiddenReportParts = {};
        }

        hiddenReportParts.specificationTypeId = store.currentSpecTypeId;
        hiddenReportParts.hiddenReportParts[section] = checked ? [] : topicsInSection;
        store.setProjectHiddenReportParts(project.id, hiddenReportParts);
    };

    const onChangeTopicSwitch = (section: string, topic: string, checked: boolean) => {
        const project = store.currentProject;
        if (!project) {
            return;
        }

        const existingHiddenReportPart = project.hiddenReportParts?.find(p => p.specificationTypeId === store.currentSpecTypeId);
        let hiddenReportParts = existingHiddenReportPart ?? new HiddenReportPartModel();

        if (!hiddenReportParts.hiddenReportParts) {
            hiddenReportParts.hiddenReportParts = {};
        }

        hiddenReportParts.specificationTypeId = store.currentSpecTypeId;
        hiddenReportParts.hiddenReportParts[section] = checked 
            ? hiddenReportParts.hiddenReportParts[section]?.filter((t: string) => t !== topic) ?? [] 
            : [...hiddenReportParts.hiddenReportParts[section] ?? [], topic];
        store.setProjectHiddenReportParts(project.id, hiddenReportParts);
    };

    const getMenu = () => {
        const sections = Object.keys(store.groupedTableDataSource);

        if (!sections.length) {
            return null;
        }

        return (
            <>
                {sections.map(s => (
                    <div key={s} className="ar-report-layout-menu-section">
                        <div className="ar-report-layout-menu-section-title">
                            <div className="title-container">
                                {s}
                            </div>
                            <div className="switch-container">
                                <Switch checked={!isSectionSwitchDisabled(s)} onChange={(checked) => onChangeSectionSwitch(s, checked)} />
                            </div>
                        </div>
                        {store.groupedTableDataSource[s].map(t => (
                            <div key={`${s}:${t.topic}`} className="ar-report-layout-menu-topic">
                                <div className="title-container">
                                    {t.topic}
                                </div>
                                <div className="switch-container">
                                    <Switch checked={!isTopicSwitchDisabled(t.section, t.topic)} onChange={(checked) => onChangeTopicSwitch(s, t.topic, checked)}/>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </>);
    };

    return (
        <div className="ar-report-menu-container">
            <Popover 
                placement="bottomRight" 
                overlayClassName="ar-report-layout-menu-popover" 
                content={getMenu()} 
                trigger={['click']}
                getPopupContainer={(node) => node.parentNode as HTMLElement}
            >
                <Button type="link" className="ar-report-layout-menu-button" icon={<i className='roche-icon hide-eye' />}>
                    {getButtonText()}
                </Button>
            </Popover>
        </div>
    );
};

export default observer(ARReportLayoutMenu);