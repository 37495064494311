import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import PushClient from '../../common/services/PushClient';
import { SortModel } from '../../common/types/SortModel';
import { DocumentStateChange } from '../../common/types/DocumentStateChange';
import { ColumnModel } from '../../common/types/ColumnModel';
import ProjectsService from '../services/ProjectsService';
import { Analyzer, HiddenReportPartModel } from '../types';
import Project from '../types/Project';
import { HistoryData } from '../types/History';
import { CommentData } from '../types/CommentData';
import { DocumentTopicChange } from '../../common/types/DocumentTopicChange';
import { message } from 'antd';
import _ from 'lodash';

const defaultColumnsConfig = [
    {isVisible: true, name: 'ID', id: 'id'}, 
    {isVisible: true, name: 'Project Name', id: 'name'}, 
    {isVisible: true, name: 'Product Name', id: 'productName'}, 
    {isVisible: true, name: 'Development Lead', id: 'developmentLead'}, 
    {isVisible: true, name: 'Instruments', id: 'analyzer'}, 
    {isVisible: true, name: 'Created by', id: 'createdBy'}, 
    {isVisible: true, name: 'Creation date', id: 'createdDate'}, 
    {isVisible: true, name: 'Updated by', id: 'updatedBy'}, 
    {isVisible: true, name: 'Update date', id: 'updateDate'}, 
    {isVisible: true, name: 'Status', id: 'status'},
];

class ProjectsStore {
    projects: Project[] = [];

    projectColumns: ColumnModel[] = defaultColumnsConfig;

    analyzers: Analyzer[] = [];

    isLoadingProjects: boolean = true;

    isLoadingLayout: boolean = true;

    isLoadingAnalyzers: boolean = true;
    
    specificationDoc: Observable<DocumentStateChange>;

    verificationDoc: Observable<DocumentStateChange>;

    activityData: Observable<HistoryData>;

    documentTopicChange: Observable<DocumentTopicChange>;

    newCommentData: Observable<CommentData>;

    sortOrder: SortModel = {
        field: 'createdDate',
        order: 'descend'
    };

    selectedProject: string;

    isArchivedViewEnabled: boolean = false;

    constructor(private service: ProjectsService) {
        const pushClient = new PushClient();
        const pkg = pushClient.createPackageListener().publish();
        const doc = pushClient.createVerificationDocumentListener().publish();
        const activity = pushClient.createActivityDataListener().publish();
        const newComment =  pushClient.createNewCommentDataListener().publish();
        const documentTopicChange = pushClient.createDocumentTopicChangeListener().publish();
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const filterPkgsByExistentProject = filter<any>(p => 
            !!this.projects.find(prj => prj.id === p.projectId)
        );

        this.specificationDoc = pkg.pipe(filterPkgsByExistentProject);
        this.verificationDoc = doc.pipe(filterPkgsByExistentProject);
        this.activityData = activity.pipe(filterPkgsByExistentProject);
        this.newCommentData = newComment.pipe(filterPkgsByExistentProject);
        this.documentTopicChange = documentTopicChange.pipe(filterPkgsByExistentProject);
        pkg.connect();
        doc.connect();
        activity.connect();
        newComment.connect();
        documentTopicChange.connect();
        makeObservable(this, {
            createNewProject: action.bound,
            handleColumnsReorder: action.bound,
            handleVisibiltyOnChange: action.bound,
            saveLayout: action.bound,
            setLayoutDefaultView: action.bound,
            setIsLoadingProjects: action.bound,
            setIsLoadingLayout: action.bound,
            setSortOrder: action.bound,
            setIsLoadingAnalyzers: action.bound,
            setProjectHiddenReportParts: action.bound,
            projects: observable,
            projectColumns: observable,
            isLoadingProjects: observable,
            isLoadingLayout: observable,
            isLoadingAnalyzers: observable,
            sortOrder: observable,
            isArchivedViewEnabled: observable,
            tableColumns: computed
        });
    }

    get tableColumns() {
        return [...this.projectColumns, {name: 'Actions', isVisible: true}];
    }

    get isProjectDataLoading() {
        return this.isLoadingAnalyzers;
    }

    setIsLoadingProjects(isLoading: boolean) {
        this.isLoadingProjects = isLoading;
    }

    setIsLoadingLayout(isLoading: boolean) {
        this.isLoadingLayout = isLoading;
    }

    setIsLoadingAnalyzers(isLoading: boolean) {
        this.isLoadingAnalyzers = isLoading;
    }

    async createNewProject(formValues: unknown) {
        await this.service.createNewProject(formValues);
    }
    
    setSelectedProject(projectId: string) {
        this.selectedProject = projectId;
    }

    updateProject(projectId: string, formValues: unknown) {
        return this.service.updateProject(projectId, formValues);
    }

    async getProjects(searchTerm?: string, onlyArchived?: boolean, includeArchived?: boolean) {
        this.setIsLoadingProjects(true);
        const resp = await this.service.getProjects(searchTerm, onlyArchived, includeArchived);
        runInAction(() => {
            this.projects = resp;
        });
        this.setIsArchivedView(!!onlyArchived);
        this.setIsLoadingProjects(false);
    }

    setSortOrder(sortOrder: SortModel) {
        this.sortOrder = sortOrder;
    }

    getSortOrder(field: string) {
        return this.sortOrder.field === field ? this.sortOrder.order : undefined;
    }

    setIsArchivedView(isEnabled: boolean) {
        this.isArchivedViewEnabled = isEnabled;
    }

    handleColumnsReorder(dragIndex: number, dropIndex: number) {
        const draggable = this.projectColumns[dragIndex];
        const columns = this.projectColumns;
        columns.splice(dragIndex, 1);
        columns.splice(dropIndex, 0, draggable);
        this.projectColumns = columns;
        this.saveLayout();
    }

    handleVisibiltyOnChange(name: string, isChecked: boolean) {
        const index = this.projectColumns.findIndex(x=> x.name === name);
        const cols = this.projectColumns.slice();
        cols[index].isVisible = isChecked;
        this.projectColumns = cols;
        this.saveLayout();

    }

    async archiveProject(projectId: string) {
        await this.service.archiveProject(projectId);
        const index = this.projects.findIndex(p => p.id === projectId);
        const projects = this.projects.slice();
        projects.splice(index, 1);
        runInAction(() =>  this.projects = projects);
    }

    async restoreProject(projectId: string) {
        await this.service.restoreProject(projectId);
        const index = this.projects.findIndex(p => p.id === projectId);
        const projects = this.projects.slice();
        projects.splice(index, 1);
        runInAction(() =>  this.projects = projects);
    }

    async duplicateProject(projectId: string, projectName: string) {
        const resp = await this.service.duplicateProject(projectId, projectName);

        if (resp.isOk()) {
            message.success('Project has been successfully duplicated.');

            this.getProjects(undefined, this.isArchivedViewEnabled, undefined);
        } else {
            message.error('Failed to duplicate project.');
        }
    }

    async isProjectBusy(projectId: string) {
        const resp = await this.service.isProjectBusy(projectId);

        if (!resp.isOk()) {
            return false;
        }

        return resp.unwrapOr(false);
    }

    async saveLayout() {
        await this.service.saveProjectsListLayout(this.projectColumns);
    }

    async fetchLayoutConfig() {
        this.setIsLoadingLayout(true);
        const resp = await this.service.getProjectListLayoutForUser();
        runInAction(() => {
            this.projectColumns = resp.columns.length ? resp.columns.filter(c => defaultColumnsConfig.some(dc => dc.name === c.name)) : defaultColumnsConfig;
        });
        this.setIsLoadingLayout(false);
    }

    setLayoutDefaultView() {
        this.projectColumns = defaultColumnsConfig;
        return this.saveLayout();
    }

    async getAnalyzers() {
        this.setIsLoadingAnalyzers(true);
        const resp = await this.service.getAnalyzers();
        runInAction(() => {
            this.analyzers = resp;
        });
        this.setIsLoadingAnalyzers(false);
    }

    async setProjectHiddenReportParts(projectId: string, hiddenReportParts: HiddenReportPartModel) {
        const project = this.projects?.find(p => p.id === projectId);
        if (!project) {
            return;
        }

        try {
            const projectsIndex = this.projects.findIndex(p => p.id === projectId);
            let newProjects = _.cloneDeep(this.projects);

            if (!newProjects[projectsIndex].hiddenReportParts || !newProjects[projectsIndex].hiddenReportParts?.length) {
                newProjects[projectsIndex].hiddenReportParts= [hiddenReportParts];
            } else {
                newProjects[projectsIndex].hiddenReportParts = newProjects[projectsIndex]
                    .hiddenReportParts?.filter(hrp => hrp.specificationTypeId !== hiddenReportParts.specificationTypeId);
                newProjects[projectsIndex].hiddenReportParts!.push(hiddenReportParts);
            }  

            runInAction(() => {
                this.projects = newProjects;
            }); 

            const resp = await this.service.updateProjectHiddenReportParts(projectId, hiddenReportParts);

            if (!resp.isOk()) {
                message.error('Failed to update project hidden report parts.');
                console.error(resp.error);
            }    
        } catch (error) {
            message.error('Failed to update project hidden report parts.');
            console.error(error);
        }
    }
}

export default ProjectsStore;