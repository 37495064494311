import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import SummaryReportStore from '../stores/SummaryReportStore';
import CommentForm from './CommentForm';
import { ValueEditor } from '.';
import { HTMLRenderer } from '../../common/components';
import { Utils } from '../../common/misc/Utils';

type Props = {
    store: SummaryReportStore;
    isDataChangeModalVisible: boolean;
    closeDialog: () => void
};

const EditValueForm: React.FC<Props> = ({store, isDataChangeModalVisible, closeDialog}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        return ()=> {
            form.resetFields(['comment', 'newVal']);
        };
    });

    const handleSaveClick = () => {
        form.validateFields().then(values => {
            store.editCellData(
                store.editableSectionName,
                Utils.removeNonBreakingSpaces(values.newVal),
                Utils.removeNonBreakingSpaces(values.comment)
            );
            closeDialog();
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <Form id="summary-report-edit-value-form" form={form} className="alpha-form" layout='vertical' onFinish={handleSaveClick}>
            <Form.Item
                colon={false}
                label={isDataChangeModalVisible ? 'Original value' : 'Current value'}
                name="currentVal"
                style={{marginBottom: 10}}
            >
                <div style={{ maxHeight: 300, overflow: 'auto' }}>
                    {store.currentValue ? (
                        <div>
                            <HTMLRenderer html={store.currentValue.replace(/[\n\r]/g, '<br/>')}/>
                        </div>
                    ) : (
                        <i className="roche-icon md dash" />
                    )}
                </div>
            </Form.Item>
            
            <ValueEditor form={form} store={store} isDataChangeModalVisible={isDataChangeModalVisible} />

            <CommentForm form={form} value={store.editedDataModalComment} label='Comment' isRequired isDisabled={isDataChangeModalVisible}/>
        </Form>
    );
};

export default observer(EditValueForm);