import { observer } from 'mobx-react-lite';
import ApplicationReportsStore from '../../stores/ApplicationReportsStore';
import './HiddenSectionTopicsMenu.less';
import * as React from 'react';
import { Button, Popover, Switch } from 'antd';
import { HiddenReportPartModel } from '../../../projects/types';

type Props = {
    section: string;
    store: ApplicationReportsStore
};

const HiddenSectionTopicsMenu: React.FC<Props> = ({section, store}) => {
    const getButtonText = () => {
        if (!store.currentSpecTypeHiddenParts?.hiddenReportParts?.[section]) {
            return null;
        }

        const hiddenTopicsCount = store.currentSpecTypeHiddenParts?.hiddenReportParts?.[section].length;
        return `${hiddenTopicsCount} hidden topic${hiddenTopicsCount > 1 ? 's' : ''}`;
    };

    const onChangeTopicSwitch = (topic: string, checked: boolean) => {
        const project = store.currentProject;
        if (!project) {
            return;
        }

        const existingHiddenReportPart = project.hiddenReportParts?.find(p => p.specificationTypeId === store.currentSpecTypeId);
        let hiddenReportParts = existingHiddenReportPart ?? new HiddenReportPartModel();

        if (!hiddenReportParts.hiddenReportParts) {
            hiddenReportParts.hiddenReportParts = {};
        }

        hiddenReportParts.specificationTypeId = store.currentSpecTypeId;
        hiddenReportParts.hiddenReportParts[section] = checked 
            ? hiddenReportParts.hiddenReportParts[section]?.filter((t: string) => t !== topic) ?? [] 
            : [...hiddenReportParts.hiddenReportParts[section] ?? [], topic];
        store.setProjectHiddenReportParts(project.id, hiddenReportParts);
    };

    
    const isTopicSwitchDisabled = (topic: string) => {
        const project = store.currentProject;
        if (!project?.hiddenReportParts?.find(p => p.specificationTypeId === store.currentSpecTypeId)?.hiddenReportParts?.[section]?.includes(topic)) {
            return false;
        }

        return true;
    };

    const getMenu = () => {
        const hiddenTopics = store.currentSpecTypeHiddenParts?.hiddenReportParts?.[section];
        if (!hiddenTopics) {
            return null;
        }

        return (
            <>
                {hiddenTopics.map(t => (
                    <div key={t} className="ar-report-section-menu-topic">
                        <div className="title-container">
                            {t}
                        </div>
                        <div className="switch-container">
                            <Switch checked={!isTopicSwitchDisabled(t)} onChange={(checked) => onChangeTopicSwitch(t, checked)}/>
                        </div>
                    </div>
                ))}
            </>);
    };
    
    return (
        <div className="ar-report-menu-section-container">
            <Popover 
                placement='bottomLeft'
                overlayClassName="ar-report-section-menu-popover"
                content={getMenu()} 
                trigger={['click']}
                getPopupContainer={(node) => node.parentNode as HTMLElement}
            >
                <Button type="link" className="ar-report-section-menu-button" icon={<i className='roche-icon hide-eye' />}>
                    {getButtonText()}
                </Button>
            </Popover>
        </div>
        
    );
};

export default observer(HiddenSectionTopicsMenu);