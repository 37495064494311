import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

type Props = {
    setAsNaDisabled: boolean;
    setNeedForProjectDisabled: boolean;
    setAsNaHandler: () => void;
    setNeedForProjectHandler: () => void;
    scope: 'topic' | 'section'
};

const LotsBulkUpdateMenu: React.FC<Props> = ({setAsNaDisabled, setNeedForProjectDisabled, setAsNaHandler, setNeedForProjectHandler, scope}) => {
    const getMenuPopupContainer = (node: HTMLElement) => {
        return node.parentElement as HTMLElement;
    };

    const menu = (
        <Menu 
            className='table-actions-menu' 
            data-id-type="popup-menu-list-root" 
        >
            <Menu.Item 
                key='set-as-na' 
                data-id-type="popup-menu-list-item"
                onClick={setAsNaHandler}
                disabled={setAsNaDisabled}
            >
                Set {scope} as N/A
            </Menu.Item>
            <Menu.Item 
                key='need-for-project' 
                data-id-type="popup-menu-list-item"
                onClick={setNeedForProjectHandler}
                disabled={setNeedForProjectDisabled}
            >
                Need for project
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} overlayStyle={{width: 150}} trigger={['click']} getPopupContainer={getMenuPopupContainer}>
            <Button className='action-button' type="link" icon={<i className='roche-icon action-more-dots' />}/>
        </Dropdown>
    );
};

export default observer(LotsBulkUpdateMenu);