import { range } from 'lodash';
import { LotType } from '../../summary_report/types/TopicLotResult';
import { Project } from '../../projects/types';

export default class ResultHelpers {
    static createLotType(resultNumber: number): LotType {
        return `Result${resultNumber}`;
    }

    static getRange(project?: Project) {
        if (!project) {
            return [];
        }

        return range(1, project.resultCount + 1);
    }
}
