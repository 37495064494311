export enum ReportStatus {
    Draft = 'Draft',
    InReview = 'InReview',
    Approved = 'Approved',
    NotAvailable = 'NotAvailable'
}

export enum SpecificationTypes {
    Reagent = 'Reagent',
    Calibrators = 'Calibrators',
    PreciControl = 'PreciControl'
}