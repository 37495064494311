import { RcFile } from 'antd/lib/upload';
import { ColumnModel } from '../../common/types/ColumnModel';
import appClient from '../../common/services/AppClient';
import { ProjectListLayout, Project, DvpMatrixConfig, RocheDocument, Analyzer, HiddenReportPartModel } from '../types';
import Specfication from '../types/Specification';
import { SpecificationDocument } from '../types/SpecificationDocument';
import security from '../../common/services/SecurityService';
import SpecficationType from '../../common/types/SpecificationType';
import { TopicLotResult } from '../../summary_report/types/TopicLotResult';
import { TopicsBySpec } from '../../summary_report/types/TopicsBySpecs';


export default class ProjectsService {
    async createNewProject(formValues: unknown) {
        const url = process.env.REACT_APP_SERVICE_URL + 'projects';
        const resp  = await appClient.post(url, formValues);
        return resp.mapErr(err => {
            console.error('Error occured while creating new project');
            return new Error(err.text);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async updateProject(projectId: string, formValues: any) {
        const request = {
            projectId,
            ...formValues
        };

        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}`;
        return appClient.update(url, request);
    }


    async getProjects(searchTerm?: string, onlyArchived?: boolean, includeArchived?: boolean) {
        let url = process.env.REACT_APP_SERVICE_URL + 'projects';
        if (searchTerm || onlyArchived || includeArchived) {
            url = `${url}?`;
        }
        if (searchTerm) {
            url += `searchTerm=${searchTerm}&`;
        }
        if (onlyArchived) {
            url += `onlyArchived=${onlyArchived}&`;
        }
        if (includeArchived) {
            url += `includeArchived=${includeArchived}&`;
        }

        const resp = await appClient.get<Project[]>(url);
        return await resp.unwrapOr(Array<Project>());
    }

    async saveProjectsListLayout(columns: ColumnModel[]) {
        const request = {
            columns
        };
        const url = `${process.env.REACT_APP_SERVICE_URL}layout`;
        const resp = await appClient.post(url, request);
        return resp.mapErr(err => {
            console.error('Error occured while saving project list layout');
            return new Error(err.text);
        });
    }

    async getProjectListLayoutForUser() {
        const url = `${process.env.REACT_APP_SERVICE_URL}layout`;
        const resp = await appClient.get<ProjectListLayout>(url);
        return resp.unwrapOr(new ProjectListLayout());
    }

    async getAnalyzers() {
        const url = `${process.env.REACT_APP_SERVICE_URL}analyzers`;
        const resp = await appClient.get<Analyzer[]>(url);
        return resp.unwrapOr(Array<Analyzer>());
    }

    async getDvpMatrixConfig(instrumentId: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/matrix?instrumentId=${instrumentId}`;
        const resp = await appClient.get<DvpMatrixConfig>(url);
        return resp.unwrapOr(new DvpMatrixConfig());
    }

    async getProjectDocuments(projectId: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/documents?projectId=${projectId}`;
        const resp = await appClient.get<RocheDocument[]>(url);
        return resp.unwrapOr(Array<RocheDocument>());
    }

    async uploadProjectDocument(projectId: string, matrixField: string, type: string, file: RcFile) {
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('matrixField', matrixField);
        formData.append('type', type);
        formData.append('file', file);

        const url = process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/documents`;
        return await appClient.post(url, formData);
    }

    async deleteProjectDocument(projectId: string, documentId: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/documents/${documentId}`;
        return await appClient.delete(url);
    }

    async getDocumentFromStorage(projectId: string, documentId: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/documents/${documentId}`;
        const resp = await appClient.get<string>(url);
        return resp.unwrapOr('');
    }

    async archiveProject(projectId: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/archive`;
        const resp =  await appClient.post<Project>(url);
        return resp.mapErr(err => {
            console.error(`Error occured while archiving project ${projectId}`);
            return new Error(err.text);
        });
    }

    async restoreProject(projectId: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/restore`;
        const resp =  await appClient.post<Project>(url);
        return resp.mapErr(err => {
            console.error(`Error occured while restoring project ${projectId}`);
            return new Error(err.text);
        });
    }

    async duplicateProject(projectId: string, projectName: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/duplicate`;
        return await appClient.post<Project>(url, { projectId, projectName });
    }

    async isProjectBusy(projectId: string) {
        const url = process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/is_busy`;
        return await appClient.get<boolean>(url);
    }

    async getSpecifications() {
        const url = `${process.env.REACT_APP_SERVICE_URL}specification_types`;
        const resp = await appClient.get<Specfication[]>(url);
        return resp.unwrapOr(Array<Specfication>());
    }

    async getSpecificationDocuments(projectId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_documents`;
        const resp = await appClient.get<SpecificationDocument[]>(url);
        return resp.unwrapOr(Array<SpecificationDocument>());
    }

    async deleteSpecificationDocument(projectId: string, docId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_documents/${docId}`;
        await appClient.delete(url);
    }

    async getSpecificationTypes() {
        const url = `${process.env.REACT_APP_SERVICE_URL}specification_types`;
        const resp = await appClient.get<SpecficationType[]>(url);
        return resp.unwrapOr(Array<SpecficationType>());
    }

    async getTopicValues(projectId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_types/topics`;
        const resp = await appClient.get<TopicsBySpec[]>(url);
        return resp.unwrapOr(Array<TopicsBySpec>());
    }

    async getTopicValusBySpec(projectId: string, specTypeId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_types/${specTypeId}/topics`;
        const resp = await appClient.get<TopicsBySpec>(url);
        return resp.unwrapOr(null);
    }

    async getLots(projectId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/topics`;
        const resp = await appClient.get<TopicLotResult>(url);
        return resp.unwrapOr(null);
    }
    
    handleSpecDownload(projectId: string, docId: string, useOverrideDocument: boolean = false) {
        const encodedDocId = encodeURIComponent(docId);
        const encodedProjId = encodeURIComponent(projectId);

        security.invoke((token) => {
            const useOverrideDocumentParam = `&useOverrideDocument=${useOverrideDocument}`;
            const t = '?access_token=' + encodeURIComponent(token);
            const url = `${process.env.REACT_APP_SERVICE_URL}projects/${encodedProjId}/specification_documents/${encodedDocId}/download${t}${useOverrideDocumentParam}`;
            window.open(url);
            return Promise.resolve();
        });
    }

    async addExisitingDocument(projectId: string, packageId: string, specTypeId: string) {
        const request = {
            projectId,
            packageId,
            typeId: specTypeId,
        };
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_documents/${specTypeId}/add/${packageId}`;
        const resp = await appClient.post(url, request);
        return resp.mapErr(err => {
            console.error(`Error occured while adding existing document ${projectId}`);
            return new Error(err.text);
        });
    }

    // eslint-disable-next-line no-unused-vars
    async uploadSpecDoc(projectId: string, specificationTypeId: string, file: RcFile, skipAnalysis: boolean = false, comment?: string) {
        const formData = new FormData();
        formData.append('file', file);
        if (comment) {
            formData.append('comment', comment);
        }

        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_documents/${specificationTypeId}?skipAnalysis=${skipAnalysis}`;
        return await appClient.post(url, formData);  
    }

    async updateProjectHiddenReportParts(projectId: string, hiddenReportParts: HiddenReportPartModel) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/hidden_report_parts`;
        return await appClient.post(url, hiddenReportParts);
    }
}